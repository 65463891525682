import React from 'react'
import loveBarLogo from "../assets/images/loveBar.png"
import sveucilisteDu from "../assets/images/sveucilisteDubrovnik.png"
import lightMedija from "../assets/images/light_Media.png"
import { Link } from 'react-router-dom';

const Partners = () => {
  return (
    <>
        <section className='relative h-full xl:h-[350px] w-full bg-white flex flex-col justify-center items-center'>
            <div className='flex flex-col content-center xl:flex-row xl:gap-x-36'>
                <div className='flex items-center content-center justify-center'>
                    <img className='h-[180px] w-[180px] xl:h-[250px] xl:w-[250px]' src={loveBarLogo} alt="" />
                </div>
                <div className='content-center mx-auto'>
                    <img className='h-[120px] xl:h-[150px] w-[270px] xl:w-[300px]' src={sveucilisteDu} alt="" />
                </div>
                <div className='content-center mx-auto'>
                    <img className='h-[220px] xl:h-[300px] w-[300px] xl:w-[380px]' src={lightMedija} alt="" />
                </div>
            </div>
            <div className='absolute bottom-0 h-[50px] border-t-[#261539] border-t-2 w-full bg-[#261539] px-2'>
                <div className='flex flex-row content-center flex-grow h-full justify-center items-center'>
                    <p className='text-sm span font-thin w-[33%] justify-center text-left content-center items-center normal-font'>
                        <Link onClick={() => { setTimeout(() => {window.scroll(0,0);}, 500)  }} to='/privacypolicy'>Privacy Policy</Link></p>
                    <p className='w-[33%] text-sm span text-center justify-center content-center items-center normal-font'>Copyright © 2024 PEP Konf</p>
                    <p className='span font-thin text-sm w-[33%] justify-center text-right content-center items-center normal-font'>Made by <a href="https://www.afdu.dev"><span className='text-[#F0E2FF]'>AFDU</span></a></p>
                </div>
            </div>
        </section>
    </>
  )
}

export default Partners
